import React, { Component } from 'react';
import Classnames from 'classnames';
import './index.scss';
import Config from 'config.js';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import closeJivoChat from 'shared/closeJivoChat';

class Thumbnail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hash: null,
    };
  }
  setHash(hash) {
    this.setState({
      hash,
    });
  }
  render() {
    const { hash } = this.state;
    let style = {};

    if (hash) {
      style = {
        backgroundImage: `url(${AssetHelper.imagePath(hash, ImageVariants.WIDE)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      };
    }

    return <div style={style} />;
  }
}

export default class EvrPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presenting: false,
      sceneId: null,
      presentationId: null,
      sceneName: null,
      thumbnailHash: null,
      flatApp: (location.search.indexOf('app') > -1),
      overlayVisible: true,
      zoom: 1,
    };

    this.instance = null;

    this._onChangeScene = this._onChangeScene.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
  }
  _onChangeScene(e) {
    this.setState({
      ...this.state,
      sceneId: e.sceneId,
      sceneName: e.sceneName,
    });
  }
  getInstance() {
    return this.instance;
  }
  load(presentation, presentationMeta) {
    closeJivoChat();
    this.setState({
      ...this.state,
      presenting: true,
      presentationId: presentation.id,
    });
    this.playerContainer.classList.remove('isHidden');
    if (presentationMeta && presentationMeta.owner && presentationMeta.owner.brand && presentationMeta.owner.brand !== 'NONE') {
      this.instance.setBrand(presentationMeta.owner.brand);
    } else {
      this.instance.setBrand();
    }
    this.instance.load(presentation).then(() => {
      this.instance.resize();
    });
  }

  setThumbnailHash(thumbnailHash) {
    this.playerContainer.classList.remove('isHidden');

    if (thumbnailHash) {
      this.thumbnailContainer.setHash(thumbnailHash);
      this.setState({
        thumbnailHash,
      });
    }
  }
  unload() {
    this.setState({
      presenting: false,
      thumbnailHash: null,
    });
    this.playerContainer.classList.add('isHidden');
    this.instance && this.instance.unload();
    if (window.isJivoLoaded) {
      let jivoElement = window.document.getElementsByClassName('globalClass_ET');
      if (jivoElement.length) {
        jivoElement[0].style.display = "inline";
      }
    }
  }

  componentDidMount() {
    this.unload();
    this.instance = new evr.Player({
      stats: Config.player && Config.player.stats,
      element: this.playerContainer,
      rotateScene: this.props.rotateScene || false,
      manageState: (this.props.manageState !== undefined ? this.props.manageState : true),
      context: '/',
      forceDesktopControls: this.props.forceDesktopControls
    });
    if (this.state.flatApp) {
      window.player = this.instance;

      window.setVRMode = () => {
        this.instance.toggleVRMode();
      };
    }
    if (Config.build && Config.build.cdn) {
      this.instance.setConfig({ baseUrl: Config.baseUrl, assetUrl: Config.build.cdn });
    }
    this.instance.on('changeScene', this._onChangeScene);
  }
  componentWillUnmount() {
    this.instance && this.instance.remove();
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { overlayVisible } = this.state;

    if (this.playerContainer) {
      if (!overlayVisible && nextState.overlayVisible) {
        this.playerContainer.classList.remove('evr-player--no-overlay');
      }
      if (overlayVisible && !nextState.overlayVisible) {
        this.playerContainer.classList.add('evr-player--no-overlay');
      }
    }

    return false;
  }
  changeZoom(value) {
    this.instance.setZoom({ zoom: value, animating: true });
    this.setState({
      zoom: value,
    });
  }
  zoomIn(e) {
    let newZoom = Math.min(this.state.zoom + 0.2, 4.0);

    this.instance.setZoom({ zoom: newZoom, animating: true });
    this.setState({
      zoom: newZoom,
    });
  }
  zoomOut(e) {
    let newZoom = Math.max(this.state.zoom - 0.2, 0.6);

    this.instance.setZoom({ zoom: newZoom, animating: true });
    this.setState({
      zoom: newZoom,
    });
  }
  enableGyroscope() {
    return this.instance.enableGyroscope();
  }
  disableGyroscope() {
    return this.instance.disableGyroscope();
  }
  isGyroscopeEnabled() {
    return this.instance.isGyroscopeEnabled();
  }
  openMenu() {
    if (this.props.presentation.spaceplans && this.props.presentation.spaceplans.length) {
      this.instance.openSpaceplans();
    } else {
      this.instance.openMenuScene();
    }
  }
  closeMenu() {
    if (this.props.presentation.spaceplans && this.props.presentation.spaceplans.length) {
      this.instance.closeSpaceplans();
    }
  }
  isVRCompatible() {
    return new Promise((resolve, reject) => {
      if (this.instance) {
        this.instance.isVRCompatible().then(
          compatible => { compatible ? resolve() : reject(); },
          reject
        );
      } else {
        reject();
      }
    });
  }
  toggleVRMode() {
    if (this.instance) {
      return this.instance.toggleVRMode();
    }
  }
  changeScene(options) {
    if (this.instance) {
      this.instance.stopAutoplay();
      this.instance.changeScene(options);
    }
  }
  prevScene() {
    try {
      var currentScenes = this.props.presentation.scenes;
      var found = false;
      for (var i = 0; i < currentScenes.length; i++) {
        if (currentScenes[i].id === this.state.sceneId) {
          found = true;
          break;
        }
      }

      if (found) {
        var j = i,
          nextSceneId;
        while (j > -1 && j < currentScenes.length) {
          j--;

          if (currentScenes[j].isVisible !== false) {
            nextSceneId = currentScenes[j].id;
            break;
          }
        }
        if (nextSceneId) this.instance.changeScene({ sceneId: nextSceneId });
      }
    } catch (e) { }
  }
  nextScene() {
    try {
      var currentScenes = this.props.presentation.scenes;
      var found = false;
      for (var i = 0; i < currentScenes.length; i++) {
        if (currentScenes[i].id === this.state.sceneId) {
          found = true;
          break;
        }
      }

      if (found) {
        var j = i,
          nextSceneId;
        while (j > -1 && j < currentScenes.length) {
          j++;

          if (currentScenes[j].isVisible !== false) {
            nextSceneId = currentScenes[j].id;
            break;
          }
        }
        if (nextSceneId) this.instance.changeScene({ sceneId: nextSceneId });
      }
    } catch (e) { }
  }
  startAutoplay() {
    this.instance.startAutoplay();
  }
  stopAutoplay() {
    this.instance.stopAutoplay();
  }
  resize() {
    if (this.instance) {
      this.instance.resize();
    }
  }
  disableRotateScene() {
    this.instance.setConfig({
      rotateScene: false,
    });
  }
  enableRotateScene() {
    this.instance.setConfig({
      rotateScene: true,
    });
  }
  disableOverlay() {
    this.setState({
      overlayVisible: false,
    });
  }
  enableOverlay() {
    this.setState({
      overlayVisible: true,
    });
  }
  setBrand(brandName) {
    if (this.instance) {
      this.instance.setBrand(brandName);
    }
  }
  render() {
    const { flatApp, overlayVisible } = this.state;
    let className = Classnames({
      'evr-player': true,
      'white': flatApp,
      'evr-player--no-overlay': !overlayVisible,
    });

    return (
      <div className={className} ref={(playerContainer) => { this.playerContainer = playerContainer }}>
        <Thumbnail ref={thumbnailContainer => this.thumbnailContainer = thumbnailContainer} />
      </div>
    );
  }
}

