import React, { Component } from 'react';
import ClassNames from "classnames";
import './index.scss';
import { CSSTransitionGroup } from 'react-transition-group';
import Close from 'components/close';

const NotificationTimeout = 5000;

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.nextUid = (() => {
      let lastUid = 0;
      return () => lastUid++;
    })();
    this.state = {
      notifications: {},
    };
    
    this.remove = this.remove.bind(this);
  }
  add(msg) {
    let nextUid = this.nextUid();

    this.setState({
      notifications: {
        ...this.state.notifications,
        [nextUid]: msg,
      },
    });
    setTimeout(() => {
      this.remove(nextUid);
    }, NotificationTimeout);
  }
  remove(uid) {
    if (!this.state.notifications[uid]) return;

    let newNotifications = { ...this.state.notifications};

    delete newNotifications[uid];

    this.setState({
      notifications: newNotifications,
    })
  }
  render() {
    const { notifications } = this.state,
      className = ClassNames({
        'notifications': true,
      });
    let notificationBoxes = [];

    Object.keys(notifications).sort().reverse().forEach(key => {
      let notification = notifications[key];
      notificationBoxes.push(<div className={'notification'} key={key}>
        <div>{notification}</div>
        <Close onClick={() => this.remove(key)} />
      </div>);
    });

    return (
      <CSSTransitionGroup
        className={className}
        transitionName="fadeIn"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        {notificationBoxes}
      </CSSTransitionGroup>
    );
  }
}

export default Notifications;
