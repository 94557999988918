import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as presentationActions, selectors as presentationSelectors } from 'shared/redux/presentation';
import EvrPlayer from './containers/index.jsx';
import React from "react";

const {
  getPresentation,
  getPresentationMeta,
  getEditedSceneId,
  isLoading,
  isFound,
} = presentationSelectors;

const {
  fetchEditedPresentations,
  fetchPresentation,
  clearPresentation,
  increasePresentationViewCount,
} = presentationActions;

const mapStateToProps = state => ({
  presentation: getPresentation(state),
  presentationMeta: getPresentationMeta(state),
  editedSceneId: getEditedSceneId(state),
  isLoading: isLoading(state),
  isFound: isFound(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPresentation,
    fetchEditedPresentations,
    clearPresentation,
    increasePresentationViewCount,
  }, dispatch),
});

export const PlayerContext = React.createContext({
  player: {
    instance: null,
    sceneId: null,
    sceneName: null,
    presenting: false,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EvrPlayer);
