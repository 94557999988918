import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { translate } from 'react-i18next';
import ClassNames from 'classnames';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Divider from '@material-ui/core/Divider';
import Spinner from 'components/spinner';
import Config from 'config';
import './index.scss';
import i18next from 'i18next';

export const NavBarStyles = {
  default: null,
  minimal: 'minimal',
  minimalPlayer: 'minimalPlayer',
  transparent: 'transparent',
  dark: 'dark',
  gray: 'gray',
  black: 'black',
};

export const NavBarBrands = {
  default: null,
  lagardere: 'lagardere',
};
class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      menuOpen: false,
      headerOpen: false,
      dropdownOpen: false,
      style: null,
      brand: Config.home.ui.brand || null,
      headerAnchorEl: null,
      dropdownAnchorEl: null,
      additionalContextLinks: [],
      pageLinksVisible: props.pageLinksVisible,
      scrollY: window.scrollY,
      customLogo: null,
      actionLinks: [],
      minimalMenuVisible: !window.location.pathname.includes('/v'),
      hostConnectionStatus: window.hostConnectionStatus,
      isVr: window.isVr,
    };
    setInterval(() => {
      this.setState({
        hostConnectionStatus: window.hostConnectionStatus,
        isVr: false
      });
      if (window.location.pathname.includes('/vh/') || window.location.pathname.includes('/v/') || window.location.pathname.includes('/p/')) {
        this.setState({
          isVr: window.isVr
        });
      }
      if (window.location.pathname.includes('/vh/') || window.location.pathname.includes('/h/')) {
        this.setState({
          pageLinksVisible: false,
        });
      }
      if (window.location.pathname.includes('/vh/') && window.hostConnectionStatus === 'clientConnected') {
        this.setState({
          minimalMenuVisible: true,
        });
      } else if (window.location.pathname.includes('/vh/') && window.hostConnectionStatus === 'clientDisconnected') {
        this.setState({
          minimalMenuVisible: false,
        });
      }
    }, 100);
    this.handleOpenHeader = this.handleOpenHeader.bind(this);
    this.handleOpenDropdown = this.handleOpenDropdown.bind(this);
    this.handleCloseHeader = this.handleCloseHeader.bind(this);
    this.handleCloseDropdown = this.handleCloseDropdown.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleRegistrationClick = this.handleRegistrationClick.bind(this);
    this.toggleMenuModal = this.toggleMenuModal.bind(this);
    this.logout = this.logout.bind(this);
    this.setCustomLogo = this.setCustomLogo.bind(this);
    this.setContextLinks = this.setContextLinks.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll() {
    this.setState({
      scrollY: window.pageYOffset,
    });
  }
  handleOpenHeader(e) {
    e.preventDefault();
    this.setState({
      headerOpen: true,
      headerAnchorEl: e.currentTarget,
    });
  }
  handleOpenDropdown(e) {
    e.preventDefault();
    this.setState({
      dropdownOpen: true,
      dropdownAnchorEl: e.currentTarget,
    });
  }
  handleCloseHeader() {
    if (this.state.headerOpen) {
      this.setState({
        headerOpen: false,
        headerAnchorEl: null
      });
    }
  }
  handleCloseDropdown() {
    if (this.state.dropdownOpen) {
      this.setState({
        dropdownOpen: false
      });
    }
  }
  handleLoginClick() {
    this.props.openLoginModal();
  }
  handleRegistrationClick() {
    this.props.openRegistrationModal();
  }
  toggleMenuModal() {
    if (this.props.onToggleMenu) {
      this.props.onToggleMenu();
    }
  }
  logout() {
    this.props.handleLogout();
    this.handleCloseHeader();
  }
  hide() {
    this.setState({
      visible: false,
    });
  }
  show() {
    this.setState({
      visible: true,
    });
  }
  setContextLinks(children) {
    this.setState({
      additionalContextLinks: children || [],
    });
  }
  setActionLinks(children) {
    this.setState({
      actionLinks: children || [],
    });
  }
  setStyle(newStyle) {
    if (!newStyle || !NavBarStyles[newStyle]) {
      this.setState({
        style: NavBarStyles.default,
      });
    } else {
      this.setState({
        style: newStyle,
      });
    }
  }
  setBrand(newBrand) {
    if (!newBrand || !NavBarBrands[newBrand]) {
      this.setState({
        style: NavBarBrands.default,
      });
    } else {
      this.setState({
        style: newBrand,
      });
    }
  }
  hidePageLinks() {
    this.setState({
      pageLinksVisible: false,
    });
  }
  showPageLinks() {
    this.setState({
      pageLinksVisible: true,
    });
  }
  setCustomLogo(logoHash) {
    this.setState({
      customLogo: logoHash ? AssetHelper.imagePath(logoHash, ImageVariants.LOGOTYPE) : null,
    });
  }
  render() {
    const { currentUser, t, blur, pageLinks, menuOpen } = this.props,
      { visible,
        headerOpen,
        pageLinksVisible,
        dropdownOpen,
        headerAnchorEl,
        dropdownAnchorEl,
        additionalContextLinks,
        brand,
        style,
        customLogo,
        scrollY,
        actionLinks,
        minimalMenuVisible
      } = this.state;
    let userInfo,
      signButtons,
      className = ClassNames({
        'main-header': true,
        'main-header--blur': !!blur,
        'main-header--lagardere': brand === NavBarBrands.lagardere,
        'main-header--hidden': !visible,
        'main-header--minimal': style === NavBarStyles.minimal,
        'main-header--minimal player': style === NavBarStyles.minimalPlayer,
        'main-header--transparent': style === NavBarStyles.transparent,
        'main-header--dark': (style === NavBarStyles.dark || style === NavBarStyles.default && scrollY > 30),
        'main-header--gray': style === NavBarStyles.gray,
        'main-header--black': style === NavBarStyles.black,
      }),
      customLogoUrl = customLogo || Config.home.ui.logo,
      logo,
      pageLinkElements;

    logo =
      <div
        className="logo"
        style={{
          background: `url(${customLogoUrl}) left center`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />;

    if (pageLinksVisible) {
      let MainHeaderLink = (to, text, className) =>
        <NavLink
          smooth
          className={`main-header__link ${className || ''}`}
          activeClassName="isSelected"
          to={to}
          scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          {text}
        </NavLink>;
      let links = pageLinks.map((pageLink, i) => {
        let subLinksDropDown,
          subLinks;

        if (pageLink.subLinks) {
          subLinks = pageLink.subLinks.map((pageLink, j) => {
            if (!pageLink.to) {
              return <MenuItem onClick={this.handleCloseDropdown} key={i + ':' + j} className="main-header__item"><p>{t(pageLink.translate)}</p></MenuItem>;
            }
            return <MenuItem onClick={this.handleCloseDropdown} key={i + ':' + j} className="popover__menuItem"><NavLink to={pageLink.to}>{t(pageLink.translate)}</NavLink></MenuItem>;
          });
        }

        if (!pageLink.to && subLinks) {
          return <li key={i} className="main-header__item caret" onClick={this.handleOpenDropdown}>
            <p className="main-header__link">{t(pageLink.translate)}</p>
            <Menu
              className="main-header__popover"
              open={dropdownOpen}
              anchorEl={dropdownAnchorEl}
              onRequestClose={this.handleCloseDropdown}>
              {subLinks}
            </Menu>
          </li>;
        }

        let checkExternalLink = new RegExp("^(http|https)://", "i"),
          isExternalLink = checkExternalLink.test(pageLink.to);

        if (isExternalLink) {
          let blogPl;
          if (i18next.language.includes('pl') && pageLink.to === 'https://evryplace.com/blog') {
            blogPl = 'https://evryplace.com/pl/blog-pl/';
          }
          return <li key={i} className={`main-header__item${subLinksDropDown ? ' caret' : ''}`}>
            <a className={`main-header__link main-header__link--external`} target="_blank" rel="noopener noreferrer" href={blogPl || pageLink.to} >{t(pageLink.translate)}</a>
          </li>;
        }

        return <li key={i} className={`main-header__item${subLinksDropDown ? ' caret' : ''}`} activeClassName="isSelected">
          {MainHeaderLink(pageLink.to, t(pageLink.translate))}
        </li>;
      });

      pageLinkElements = (
        <div className="main-header__links-section">
          <ul className="main-header__links">
            {links}
          </ul>
        </div>);
    }

    if (currentUser.state === 'loggedIn') {
      let avatar, contextMenuLinks, additionalLinks, avatarMeta;

      additionalLinks = additionalContextLinks;

      contextMenuLinks = Config.home.ui.contextMenuLinks && Config.home.ui.contextMenuLinks.map((menuLink, i) => {
        if (!menuLink.roles || menuLink.roles.indexOf(currentUser.role) > -1) {
          const { to, toPage, target } = menuLink;
          let navitem = (<MenuItem className="link" onClick={this.handleCloseHeader} key={i}>
            {t(menuLink.translation)}
          </MenuItem>);

          if (!to && !toPage) return;

          return  to ? <a href={to} target={target} >{navitem}</a> :
            <NavLink to={toPage}>{navitem}</NavLink>;
        }
      });

      avatarMeta = currentUser.profile && currentUser.profile.avatar;
      if (avatarMeta && avatarMeta.hash) {
        avatar = AssetHelper.imagePath(avatarMeta.hash, ImageVariants.STANDARD);
      } else {
        avatar = AssetHelper.staticPath('/static/home/base-avatar.png');
      }

      userInfo = <React.Fragment><a onClick={this.handleOpenHeader} className="main-header__profile">
        <img src={avatar} />
        <div className="main-header__email">{currentUser.displayName || currentUser.email}</div>
        {headerOpen ? (
          <ExpandLess color={brand === 'lagardere' ? 'primary': 'common.white'}  />
        ) : (
          <ExpandMore color={brand === 'lagardere' ? 'primary': 'common.white'}  />
        )}
      </a>
      <Menu
        className="main-header__popover"
        open={headerOpen}
        anchorEl={headerAnchorEl}
        onClose={this.handleCloseHeader}
        onClick={() => this.setState({headerOpen: false})}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocusItem
      >
        {additionalLinks}
        {additionalLinks.length && <Divider />}
        {contextMenuLinks}
        <MenuItem className="popover__menuItem" onClick={this.logout}><a>{t('common.signOut')}</a></MenuItem>
      </Menu>
      </React.Fragment>;
    } else if (currentUser.state === 'loading') {
      userInfo =
        <div className="main-header__login-section">
          <Spinner />
        </div>;
    } else {
      signButtons =
        <div className="main-header__login-section">
          <a className="main-header__signup-button button" onClick={this.handleRegistrationClick}>
            {t('navBar.register')}
          </a>
          <a className="main-header__login-button button--transparent" onClick={this.handleLoginClick}>
            {t('navBar.login')}
          </a>
        </div>;
    }

    return (
      <header className={className} style={{paddingRight:`${headerOpen || dropdownOpen ? '16px' : '' }`}}>
        <nav className={`main-header__navbar${menuOpen ? ' is-active' : ''}`}>
          {menuOpen || (
            (minimalMenuVisible && !this.state.isVr && <React.Fragment>
              <div
                className={`main-header__hamburger${menuOpen ? ' main-header__hamburger--active' : ''}`}
                onClick={this.toggleMenuModal}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </React.Fragment>
            ))}
          {minimalMenuVisible && (
            brand === 'lagardere' ?
              <div className="main-header__logo">{logo}</div> :
              <NavLink className="main-header__logo" exact={true} to="/">
                {logo}
              </NavLink>
          )}
          <div className="main-header__menu">
            {userInfo}
            {signButtons}
            {actionLinks.length ?
              <div className="main-header__action-buttons">{actionLinks}</div> : null}
            {pageLinkElements}
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(translate('translations', { withRef: true })(NavBar));

export const NavBarContext = React.createContext({
  navBarInstance: null,
});
